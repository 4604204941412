/* LandingPage.css */
html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.carousel-container {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-content {
  display: flex;
  width: calc(240px * 25 * 2); /* Adjust width to fit all cards twice for seamless looping */
  animation: scroll 60s linear infinite; /* Adjusted duration for desired speed */
}

.card-item {
  flex: 0 0 auto;
  width: 240px; /* Reduced width to make images smaller */
  height: auto;
  background: linear-gradient(45deg, #1a1a1a, #2a2a2a);
  border-radius: 10px;
  margin-right: 40px; /* Reduced spacing between cards */
  padding: 0;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
}

.card-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 8px; /* Reduced margin */
}

.card-name {
  padding-top: 8px; /* Reduced padding */
  font-size: 14px; /* Reduced font size */
  font-weight: bold;
  margin-bottom: 4px;
}

.card-extra {
  color: #32cd32;
  font-size: 12px; /* Reduced font size */
}

/* Ticker Styles */
/* Ticker Styles */
.ticker-wrapper {
  overflow: hidden;
  background-color: #111;
  height: 85px; /* Increased height to accommodate more padding */
  display: flex;
  align-items: center;
}

.ticker-container {
  display: flex;
  width: fit-content;
  animation: ticker-scroll 50s linear infinite;
}

.ticker-item {
  display: flex;
  flex-direction: column; /* Stack the name and bid/ask vertically */
  align-items: center;
  margin-right: 160px; /* Increased space between ticker items */
  color: white;
  min-width: 200px; /* Set a minimum width for each ticker item */
}

.ticker-logo {
  margin-top: 5px;
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
  margin-right: 10px;
}

.ticker-name {
  font-size: 16px;
  white-space: nowrap;
  margin-top: 5px; /* Increased padding on top of card names */
  margin-bottom: 5px; /* Spacing between name and bid/ask */
  text-align: center;
}

.ticker-prices {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 180px; /* Ensure bid and ask fit on one line */
  white-space: nowrap; /* Prevent text from wrapping */
}


.ticker-bid, .ticker-ask {
  font-size: 14px;
}

.ticker-bid {
  color: #32cd32;
  margin-right: 5px; /* Spacing between bid and ask */
}

.ticker-ask {
  color: #ff6347;
  margin-left: 10px;
}

/* Animations */
@keyframes scroll {
  0% {
    transform: translateX(0%); /* Start immediately from current position */
  }
  100% {
    transform: translateX(-50%); /* Move left by half of the content width */
  }
}

@keyframes ticker-scroll {
  0% {
    transform: translateX(0%); /* Start immediately from current position */
  }
  100% {
    transform: translateX(-50%); /* Move left by half of the content width */
  }
}
